











































import Vue from "vue";
import Component from "vue-class-component";

@Component({})

export default class VisitorLayout extends Vue {

    //
    // -- computed properties
    //

    private get currentYear() {
        return new Date().getFullYear().toString();
    }

    openPrivacyPolicy() {
        const url = "https://www.nationalcode.org/privacy-statement";
        window.open(url, "_blank", "noreferrer");
    }
}

