/* eslint-disable  @typescript-eslint/no-explicit-any */
import authentication from "./Authentication";
import moment from "moment";
import eventBus from "./EventBus";

export interface ISendRequestParameters {
    path: string;
    postBody: any;
    resolve: (data: any) => void;
    reject: (error: any) => void;
    loading?: (isLoading: boolean) => void;
    isJson?: boolean;
}

module ApiClient {

    const bases = document.getElementsByTagName("base");
    export let baseUrl = bases.length > 0 ? bases[0].getAttribute("href") : "/";
    
    if(process.env.NODE_ENV === "development") {
        baseUrl = "http://localhost:58878/";
    }

    let hasAlreadyNotifiedOffline = false;

    // Sometimes we want to show the message on every request 
    // and sometime only the first time...
    export function resetHasAlreadyNotifiedOffline() {
        hasAlreadyNotifiedOffline = false;
    }

    // recurse through response objects looking for ISO 8601 dates - and parse them
    export function processDates(data: any) {
        for (const item in data) {
            if (Object.prototype.hasOwnProperty.call(data, item)) {
                if (data[item] !== null) {
                    const type: string = typeof data[item];
                    if (type === "string") {
                        const txt = data[item] as string;
                        // seven or eight digit numbers are actually valid ISO 8601 formats - but we want to ignore those
                        if (isNaN(+txt)) {
                            // if(item === "created") {
                            //     console.log("...");
                            // }
                            const m = moment(txt, moment.ISO_8601, true);
                            if (m.isValid()) {
                                if (txt.substring(0, 4) === "0001") {
                                    data[item] = new Date(-62135596800000); // 01/01/0001 means 'no value'
                                }
                                data[item] = m.toDate(); // could leave as moment?
                            }
                        }
                    }
                    else if (type === "object") {
                        processDates(data[item]);
                    }
                }
            }
        }
    }

    export function resolveUrl(path: string): string {
        let url = baseUrl || "/";
        if(!url.endsWith("/")) url += "/";
        url += path.startsWith("/") ? path.substr(1) : path;
        return url;
    }

    export function sendRequest(parameters: ISendRequestParameters) {
        const url = resolveUrl(parameters.path);
        //const button = buttonToggler.getButton(parameters.event);

        if(typeof parameters.loading === "function") parameters.loading(true);

        const headers = new Headers({
            "Authorization": authentication.apiBearerToken()
        });
        if(parameters.isJson){
            headers.append("Content-Type", "application/json");
        }
        fetch(url, {
            method: parameters.postBody ? "POST" : "GET",
            credentials: "include", // can also be set to 'omit' or 'same-origin'
            headers: headers,
            body: parameters.postBody
        }).then((response: Response) => {
            if (response.status === 401) {
                // Assume auth token has expired and emit an event to show the sign-in dialogue
                // (request will be re-issued using the parameters after successful sign-in.)
                if(typeof parameters.loading === "function") parameters.loading(false);

                authentication.onHttpUnauthorised(); 
                eventBus.$emit("http-401", parameters); // trigger sign-in dialogue
                // note that we're NOT rejecting or resolving the promise at this stage!
                return null;
            }
            else if (response.status < 200 || response.status >= 300) {
                eventBus.$emit("http-error", response); // global handler (show message box or whatever)

                if(typeof parameters.loading === "function") parameters.loading(false);

                parameters.reject(new Error("HTTP error " + response.status)); // handled where called
                return null;
            }
            else if (response.status === 204) {
                // empty response - this would cause json to fail so just return null
                console.log("Response 204 - no content");
                return null;
            }
            else if(response.headers.get("Content-Type")?.startsWith("application/json")) {
                return response.json();
            }
            else {
                // this is for file downloads...
                return response.blob();
            }
        }).then((responseData: any) => {
            if (typeof responseData === "object" && responseData !== null && parameters.isJson) {
                processDates(responseData);
            }
            else {
                console.log("#### do not process dates");
            }
            if(typeof parameters.loading === "function") parameters.loading(false);
            // reset flag if successful so we show a message next time we go offline
            hasAlreadyNotifiedOffline = false;  
            parameters.resolve(responseData);
        }).catch((error: Error) => {
            if (typeof parameters.loading === "function") parameters.loading(false);
            if (navigator.onLine) {
                console.log("API ERROR");
                console.error(error);
                eventBus.$emit("fetch-exception", error);
            }
            else if(!hasAlreadyNotifiedOffline){
                eventBus.$emit("fetch-failed-offline", error);
                hasAlreadyNotifiedOffline = true;
            }
            parameters.reject(error);

        });
    }

    function sendRequestPromise(path: string, postBody: any, loading?: (isLoading: boolean) => void , isJson?: boolean): Promise<any> {
        return new Promise((resolve: (data: any) => void, reject: (error: any) => void) => {
            sendRequest({ path, postBody, resolve, reject, loading, isJson });
        });
    }

    export function get(path: string, loading?: (isLoading: boolean) => void ): Promise<any> {
        return sendRequestPromise(path, null, loading, true);
    }

    export function post(path: string, data: any, loading?: (isLoading: boolean) => void ): Promise<any> {
        if(!(data instanceof FormData) && typeof data !== "string") {
            data = JSON.stringify(data);
        }
        return sendRequestPromise(path, data, loading, true);
    }

    export function getNonJson(path: string, loading?: (isLoading: boolean) => void ): Promise<any> {
        return sendRequestPromise(path, null, loading, false);
    }

    export function postNonJson(path: string, data: any, loading?: (isLoading: boolean) => void ): Promise<any> {
        return sendRequestPromise(path, data, loading, false);
    }

    export function uploadFile(path: string, file: File, loading?: (isLoading: boolean) => void ): Promise<any> {
        const formData = new FormData();
        formData.append("file", file);
        return sendRequestPromise(path, formData, loading, false);
    }
}

export default ApiClient;