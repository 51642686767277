import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuard, Route, RawLocation } from "vue-router";
import store from "@/store/store";
import dirtyness from "@/utilities/Dirtyness";
import { UserRole } from "@/model/Enums";

Vue.use(VueRouter as any); // eslint-disable-line @typescript-eslint/no-explicit-any

let intendedUrl: string | null = null;

// call this in the *component* code (not here in the router setup)
export function beforeRouteLeave(to: Route, from: Route,  next: (to?: string | void | false) => void) {
    if(dirtyness.isDirty && !confirm("Changes may not be saved.  Click cancel to stay on this page.")) {
        return next(false);
    }
    dirtyness.reset();
    return next();
}

const beforeEnterGuard: NavigationGuard = async (to: Route, from: Route, next: (to?: string | void) => void) => {

    //console.log(`### nav guard... signed in=${store.getters.isSignedIn} intendedUrl=${intendedUrl}`);

    if (store.getters.isSignedIn) {
        if (intendedUrl) {
            const url = intendedUrl;
            intendedUrl = null;
            console.log("### nav guard - now logged in - redirect");
            return next(url); // goto stored url
        } else {
            console.log("### nav guard - carry on...");
            return next(); // all is fine
        }
    }

    try {
        // get user from session storage
        await store.dispatch("loadSignedInUser");
    } catch (e) {
        console.log("@@@ loadSignedInUser failed ");
        console.error(e);
    }

    if (store.getters.isSignedIn) {
        console.log("### nav guard - got login ok");
        next();
    } else {
        intendedUrl = to.path; // store entry url before redirect
        console.log("### nav guard - redirect to signin");
        next("/signIn");
    }
};

//
// -- set up the routes
//

const routes: Array<RouteConfig> = [];

routes.push({ path: "/", name: "HomeSignIn", meta: { layout: "blank" }, component: () => import("@/vue/views/HomeSignIn.vue") });
routes.push({ path: "/Reset/:key", name: "ResetPassword", meta: { layout: "blank" }, component: () => import("@/vue/views/ResetPassword.vue") });
routes.push({ path: "/Activate/:key", name: "Activate", meta: { layout: "blank" }, component: () => import("@/vue/views/ResetPassword.vue") });

routes.push({ path: "/landlordRegister", name: "LandlordRegister", meta: { layout: "visitor"}, component: () => import("@/vue/views/LandlordRegister.vue")});
routes.push({ path: "/signin", name: "SignIn", meta: { layout: "visitor" }, component: () => import("@/vue/views/SignIn.vue") });
routes.push({ path: "/Error", name: "Error500", meta: { layout: "visitor" }, component: () => import("@/vue/views/Error500.vue") });

routes.push({ path: "/Profile", name: "Profile", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Profile.vue") });
routes.push({ path: "/users", name: "Users", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Users.vue") });
routes.push({ path: "/declaration", name: "Declaration", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Declaration.vue") });
routes.push({ path: "/buildings", name: "Buildings", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Buildings.vue") });
routes.push({ path: "/assessment", name: "Assessment", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Assessment.vue") });
routes.push({ path: "/verifications", name: "Verifications", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Verifications.vue") });
routes.push({ path: "/verification/:verificationID", name: "Verification", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Verification.vue") });
routes.push({ path: "/actions", name: "Actions", meta: { layout: "main" }, beforeEnter: beforeEnterGuard, component: () => import("@/vue/views/Actions.vue") });

// This needs to be the **last** route
routes.push({ path: "*", meta: { layout: "visitor" }, component: () => import("@/vue/views/Error404.vue") });

//
// -- create the router
//

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    base: process.env.BASE_URL,
    routes
});

export function goToIntendedUrl() {
    if (!intendedUrl && store.state.signedInUser?.role == UserRole.Verifier) router.replace("/verifications/"); // default in case not defined (?)
    else if (!intendedUrl && store.state.signedInUser?.role == UserRole.LandlordUser) router.replace("/buildings/");
    router.replace(intendedUrl as RawLocation);
}

export default router;
