import { mapData } from "@/utilities/DataMapping";

export interface ILookupItem {
    id: number;
    description: string;
}

export class LookupItem implements ILookupItem {
    constructor(data?: ILookupItem) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ILookupItem) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    description: string = "";

    static createLookup(id: number, description: string): LookupItem {
        return new LookupItem({
            id: id,
            description: description
        });
    }
}