import utils from "@/utilities/Utils";
import { UserRole, UserStatus } from "@/model/Enums";
import { mapData } from "@/utilities/DataMapping";

export interface IUser {
    id: string;
    roleID: number;
    role: UserRole;
    username: string;
    title: string;
    forename: string;
    surname: string;
    email: string;
    activeDirectoryName: string;
    contactNumber: string;
    landlordID: string;
    landlordDescription: string;
    lastLoggedIn: Date;
    locked: boolean;
    canEditOSAQ: boolean;
    deleted: Date;
    deletedByID: string;
    created: Date;
    createdByID: string;
    lastUpdated: Date;
    lastUpdatedByID: string;
    declarationSubmitted: boolean;
    organisationAssessmentAvailable: boolean;
    verificationsAvailable: boolean;
    incompleteVerifications: boolean;
    hasActiveBuildings: boolean;
}

export class User implements IUser {

    constructor(data?: IUser) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IUser) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    roleID: number = 0;
    role: UserRole = UserRole.None;
    username: string = "";
    title: string = "";
    forename: string = "";
    surname: string = "";
    email: string = "";
    activeDirectoryName: string = "";
    contactNumber: string = "";
    landlordID: string = utils.emptyGuidValue;
    landlordDescription: string = "";
    lastLoggedIn: Date = new Date(utils.emptyDateValue);
    locked: boolean = false;
    canEditOSAQ: boolean = false;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByID: string = utils.emptyGuidValue;
    created: Date = new Date(utils.emptyDateValue);
    createdByID: string = utils.emptyGuidValue;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByID: string = utils.emptyGuidValue;
    jwtBearerToken: string = "";
    declarationSubmitted: boolean = false;
    organisationAssessmentAvailable: boolean = false;
    verificationsAvailable: boolean = false;
    incompleteVerifications: boolean = false;
    hasActiveBuildings: boolean = false;

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }

    get fullname() {
        return this.forename + " " + this.surname;
    }
}