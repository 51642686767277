





























    import Vue from "vue";
    import Component from "vue-class-component";
    import { Ref, Watch } from "vue-property-decorator";
    import { VForm } from "@/vForm";
    import * as toastr from "toastr";
    import ApiButton from "@/vue/components/ApiButton.vue";
    import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
    import apiClient from "@/utilities/ApiClient";
    import { PasswordChangeRequest } from "@/model/PasswordChangeRequest";
    import authentication from "@/utilities/Authentication";
    import store from "@/store/store";
    import utils from "@/utilities/Utils";
    import { ISaveResponse } from "@/model/ISaveResponse";
    import { UserRole } from "@/model/Enums";
    import { ILookupItem } from "@/model/LookupItem";
    import { IUser, User } from "@/model/User";
    import { ILandlordRegistration } from "@/model/LandlordRegistration";
import { ISignInResponse } from "./SignInForm.vue";

    interface ISignInRequest {
        email: string;
        password: string;
    }

    @Component({ components: 
        { 
            ApiButton, 
            ConfirmDialogue 
        }
    })

    export default class LandlordRegistrationUserCreateDialogue extends Vue {

        //
        // -- properties
        //

        @Ref("passwordForm") private readonly passwordForm!: VForm;  

        private showDialogue: boolean = false;
        private passwordChangeReq = new PasswordChangeRequest();
        signInRequest: ISignInRequest = { email: "", password: "" };

        // get properties are 'computeds'
        get isAuthenticated(): boolean {
            return this.$store.state.signedInUser !== null;
        }

        //
        // -- validations
        //

        private passwordErrorMessages: Array<string> = [];      

        @Watch("passwordChangeReq.newPassword")
        private async validatePassword() {
            if (utils.isEmptyOrWhitespace(this.passwordChangeReq.newPassword)) {
                this.passwordErrorMessages = [];
                return;
            }

            const response: { isOkay: boolean; message: string } = await apiClient.get("/api/user/validatePassword?password=" + encodeURIComponent(this.passwordChangeReq.newPassword));
            if (response.isOkay) {
                this.passwordErrorMessages = [];
            }
            else if (!response.isOkay && this.passwordErrorMessages.indexOf(response.message) == -1) {
                this.passwordErrorMessages = [];
                this.passwordErrorMessages.push(response.message);
                return;
            }
        }

        //
        // -- methods
        //

        open(landlordRegistration: ILandlordRegistration) {
            this.passwordChangeReq.userID = landlordRegistration.id;
            this.passwordChangeReq.email = landlordRegistration.emailAddress;
            this.showDialogue = true;
        }

        private reset() {
            utils.resetObject(this.passwordChangeReq);
            this.passwordErrorMessages = [];
            this.passwordForm?.resetValidation();
        }

        private async save() {
            await this.validatePassword();
            const isValid = this.passwordForm.validate() && this.passwordErrorMessages.length === 0;

            if (!isValid) {
                toastr.warning("Please fix highlighted issues", "Cannot Save")
                return;
            } 

           var response = await apiClient.post("/api/user/createLandlordRegistrationUser", this.passwordChangeReq);
           
           if (response.message == "ok") {
                this.signInRequest.email = this.passwordChangeReq.email;
                this.signInRequest.password = this.passwordChangeReq.newPassword;

                const signInResponse: ISignInResponse = await apiClient.post("/Api/Authentication/signin", this.signInRequest);

                await authentication.signIn(new User(signInResponse.user));

                if (this.isAuthenticated) {
                    this.saveToStorage(signInResponse.user);
                    this.$emit("isAuthenticated");
                    this.reset();
                    this.showDialogue = false;
                }
                else {
                    toastr.error(signInResponse.message);
                }  
           } else {
                toastr.error("Failed to create user, please contact your system admin if this problem continues to persist");
           }          
        }

        private saveToStorage(user: IUser) {
            if (typeof (Storage) === "undefined") return;
            localStorage.setItem("user", JSON.stringify(user));
        }

    }
