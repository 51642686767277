// Same as TriState in Visual Basic
export enum TriState {
    UseDefault = -2, // 0xFFFFFFFE
    True = -1, // 0xFFFFFFFF
    False = 0
}

export enum UserRole {
    None = 0,
    UnipolAdmin = 1,
    UnipolReader = 2,
    Verifier = 3,
    ExternalReader = 4,
    Landlord = 5,
    LandlordUser = 6
}

export enum UserStatus {
    None = 0,
    InitialEmailPending = 1,
    InitialEmailSent = 2,
    InitialEmailFailed = 3,
    EmailConfirmed = 4, // means they followed the initial email link
    Active = 5 // means they set a password
}

export enum AssessmentStatus {
    OSAQ = 1,
    AssignVerifier = 2,
    ScheduleVisit = 3,
    SSAQ = 4,
    Visit = 5,
    DraftReview = 6,
    Dispute = 7,
    DisputedDraft = 8,
    DisputeUnresolvable = 9,
    Verified = 10,
    Completed = 11,
    Archived = 12
}

export enum InfoType {
    None = 0,
    DeclarationSigned = 1,
    OSAQCompleted = 2,
    SSAQCompleted = 3
}

export enum LookupGroup {
    All = 0,
    LookupOne = 1,
    LookupTwo = 2
}