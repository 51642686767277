






































































































import Vue from "vue";
import authentication from "@/utilities/Authentication";
import Component from "vue-class-component";
import store from "@/store/store";
import { UserRole } from "@/model/Enums";

@Component({})
export default class SignedInLayout extends Vue {

    //
    // -- lifecycle hooks
    //

    mounted() {
        document.body.style.backgroundColor = "#fff";
    }

    //
    // -- computed properties
    //

    private get signedInUserName(): string {
        return store.getters.signedInUserName;
    }

    private get initials(): string {
        const parts = this.signedInUserName.split(" ");
        return parts.reduce((initials, part) => initials + part.substring(0, 1), "");
    }

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    private get isLandlordPrimary() {
        return store.state.signedInUser?.role == UserRole.Landlord;
    }

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    private get declarationSubmitted() {
        return store.state.signedInUser?.declarationSubmitted;
    }

    private get organisationAssessmentAvailable() {
        return store.state.signedInUser?.organisationAssessmentAvailable;
    }

    private get verificationsAvailable() {
        var retVal = false;

        if (store.state.signedInUser?.role == UserRole.Verifier) retVal = true;
        if ((store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser) && store.state.signedInUser?.verificationsAvailable) retVal = true;
        
        return retVal;
    }

    private get landlordName() {
        return store.state.signedInUser?.landlordDescription;
    }

    //
    // -- methods
    //

    signOut() {
        // Not using async / await here so we'll just trust the sign out occurs in the background
        // and rely on error handler if anything screws up.
        authentication.signOut();

        this.$router.push("/");
    }

}
