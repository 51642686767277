








import Vue from "vue";
import Component from "vue-class-component";
import eventBus from "@/utilities/EventBus";
import apiClient, { ISendRequestParameters } from "@/utilities/ApiClient";
import SignInDialogue from "@/vue/components/SignInDialogue.vue";
import ErrorMessage, { IErrorMessageOptions } from "@/vue/components/ErrorMessage.vue";
import * as toastr from "toastr";

const defaultLayout = "visitor";

@Component({
    components: { SignInDialogue, ErrorMessage }
})
export default class App extends Vue {

    private pendingApiParameters: ISendRequestParameters | null = null;

    private errorMessageOptions: IErrorMessageOptions = {
        shouldShow: false,
        title: "",
        message: "",
        icon: "mdi-alert-circle",
        extraContent: null
    }

    mounted() {

        // Prevent any XSS shenanigans by default
        toastr.options.escapeHtml = true;

        eventBus.$on("http-401", (parameters: ISendRequestParameters) => {
            this.pendingApiParameters = parameters;
            console.log("...App component - setting promise and showing login dialogue");
            const dlg: SignInDialogue = this.$refs.signInDialogue as SignInDialogue;
            dlg.show();
        });

        eventBus.$on("http-error", (response: Response) => {
            const status = response.status;
            if (status === 500) {
                // DON'T report this via Basilisk - leave it to the server which should have already reported it.
                response.text().then(bodyText => {
                    // if we're running in production, we'll probably not get a response body - but handy to show in dev mode
                    if (bodyText) {
                        this.showErrorMessage("Server Error", "Status = " + response.status, "mdi-alert-circle", bodyText);
                    }
                    else {
                        this.showErrorMessage("Server Error", "Status = " + response.status);
                    }                       
                });
            }
            else {
                // Should we report this through basilisk? Probably not.
                this.showErrorMessage("HTTP Error", "Status = " + response.status);
            }
        });

        eventBus.$on("fetch-exception", (reason: any) => {
            const message = typeof reason === "string" 
                ? reason 
                : (typeof reason === "object" && reason.message) 
                    ? reason.message 
                    : "Failed to communicate with server";
            this.showErrorMessage("API Error", message, "mdi-cloud-alert");
        });

        eventBus.$on("fetch-failed-offline", () => {
            this.showErrorMessage("Network Problem", "Could not communicate with server.\n\nPlease check your network connection.", "mdi-lan-disconnect");
        });
    }

    onAuthenticated() {
        console.log("...App component - onAuthenticated");
        if (this.pendingApiParameters == null) {
            console.log("...App component - onAuthenticated - pendingApiParameters is **NULL**!");
            // what should we do if we ever get here?!!
            return;
        }            
        console.log("...App component - resend last API request");
        apiClient.sendRequest(this.pendingApiParameters);
        this.pendingApiParameters = null;
    }

    showErrorMessage(title: string, message: string, icon?:string, content?: string) {
        this.errorMessageOptions.shouldShow = true;
        this.errorMessageOptions.title = title;
        this.errorMessageOptions.message = message;
        this.errorMessageOptions.icon = icon ?? "mdi-alert-circle";
        this.errorMessageOptions.extraContent = content ?? null;
    }

    // computed property used to select layout - see also main.ts
    get layout() {
        if (!this.$route.meta?.layout) return null;
        return (this.$route.meta?.layout || defaultLayout) + "-layout";
    }
}
