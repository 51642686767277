






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

export interface IErrorMessageOptions {
    shouldShow: boolean;
    title: string;
    message: string;
    icon: string;
    extraContent: string|null;
}

@Component({ components: {  } })
export default class ErrorMessage extends Vue {

    @Prop({ required: true }) 
    options!: IErrorMessageOptions;

    get icon(): string {
        return this.options.icon ? this.options.icon : "mdi-alert-circle";
    }

    get title(): string {
        return this.options.title ? this.options.title : "Error";
    }

    get message(): string {
        return this.options.message ? this.options.message : "- - -";
    }

    get isExtraContent(): boolean {
        return Boolean(this.options.extraContent);
    }

}
